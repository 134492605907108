import React from 'react'
import PropTypes from 'prop-types'
import { Box, styled, Checkbox, Typography, FormControlLabel, Stack } from '@mui/material'

const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 'auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'block',
  textTransform: 'uppercase',
  fontWeight: 600,
  margin: '0 8px 0 0',
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    fontSize: '12px',
    fontWeight: 500,
  },
}))

const StyledFilterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: '0px !important',
      fontSize: '14px',
    },
  },
}))

const StyledFormControl = styled(FormControlLabel)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginRight: 0,
  },
}))

/**
 * @typedef {import('./hooks').StoreFilter} StoreFilter
 */

/**
 * Store filters component
 * @param {{ filters: StoreFilter }} props
 * @returns
 */
const StoreFilters = ({ filters }) => {
  const { state, actions } = filters
  return (
    <Wrapper>
      <StyledTypography>Store Types:</StyledTypography>
      <StyledFilterWrapper>
        <StyledFormControl
          control={<Checkbox checked={state.showroom} onChange={actions.showroom} />}
          label="Showrooms"
        />
        <StyledFormControl control={<Checkbox checked={state.kids} onChange={actions.kids} />} label="Kids" />
        <StyledFormControl control={<Checkbox checked={state.patio} onChange={actions.patio} />} label="Patio" />
        <StyledFormControl control={<Checkbox checked={state.outlet} onChange={actions.outlet} />} label="Outlet" />
      </StyledFilterWrapper>
    </Wrapper>
  )
}

StoreFilters.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default StoreFilters
