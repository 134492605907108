import React from 'react'
import PropTypes from 'prop-types'
import { Paper, styled } from '@mui/material'

import Tabs from './Tabs'

const StyledPaper = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: '10px',
  },
}))

const SortedStoreList = ({ filters, staticData }) => (
  <StyledPaper sx={{ p: 4, position: 'relative' }}>
    <Tabs filters={filters} staticData={staticData} />
  </StyledPaper>
)

SortedStoreList.propTypes = {
  filters: PropTypes.object,
  staticData: PropTypes.array.isRequired,
}
export default SortedStoreList
