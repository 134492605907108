import React, { useState, useEffect, useRef, useCallback } from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { Grid, Paper, Stack, styled } from '@mui/material'
import { arrayOf, object, shape, oneOfType, array } from 'prop-types'
import { setupAnalytics } from '@helpers/google-tag-manager'
import { StoreLocatorProvider } from '@context/storeLocatorContext/provider'
import Breadcrumbs from '@templates/strapi-cms/content-types/Breadcrumbs'
import StoreLocatorBanner from './StoreLocatorBanner'
import StoreMap from './store-map'
import SortedStoreList from './sorted-store-list'
import { useStoreFilters } from './sorted-store-list/hooks'
import StoreFilters from './sorted-store-list/StoreFilters'
import StoreSearchBar from './search-bar'

const breadcrumbData = [{ label: 'Rooms To Go Store Locator', level: 1, category: 'stores' }]

const StoreLocatorBannerContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

const StyledStoreSearchBar = styled(StoreSearchBar)(({ theme }) => ({
  zIndex: 11,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '0',
  [theme.breakpoints.down('md')]: {
    padding: '8px 0',
  },
}))

const StyledFilterAndSearch = styled(Stack)(({ theme }) => ({
  zIndex: 10,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  height: '90px',
  width: '100%',
  position: 'sticky',
  top: `112px`,
  margin: '16px 0 0 16px',
  padding: '0 32px 0px 32px',
  boxShadow: '0px -2px 4px -1px rgba(0,0,0,0.1), 0px -1px 3px 0px rgba(0,0,0,0.06)',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',
  borderBottom: 'none',
  transition: 'transform 0.3s ease-in-out',
  [theme.breakpoints.down('md')]: {
    marginLeft: '0px',
    flexDirection: 'column',
    borderBottom: '1px solid #d3d3d3',
    height: `unset`,
    marginRight: '-16px',
    width: 'calc(100% + 16px)',
    top: '64px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    top: '112px',
  },
}))

const StoreLocator = ({ data, staticData }) => {
  const divRef = useRef(null)
  const observer = useRef(null)

  const filters = useStoreFilters()

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'store', title: 'Store locator', path: '/stores' } })
  }, [])

  function setupObserver(elementToObserve) {
    observer.current = new ResizeObserver(entries => {
      const { height } = entries[0].target.getBoundingClientRect()
      if (divRef.current) {
        divRef.current.style.position = 'fixed'
        divRef.current.style.top = `${height - 16}px`
      }
      if (height > 40) {
        divRef.current.style.position = 'sticky'
        // detect innner width to set top position
        if (window.innerWidth > 639) {
          divRef.current.style.top = '64px'
        } else {
          divRef.current.style.top = '48px'
        }
      }
    })
    observer.current.observe(elementToObserve)
  }

  function removeObserver() {
    divRef.current.style.position = 'sticky'
    divRef.current.style.top = `112px`
    observer.current?.disconnect()
    observer.current = null
  }

  const onResize = useCallback(() => {
    if (window.innerWidth > 1024) {
      removeObserver()
    } else {
      const sticky = document.getElementById('main-header-mobile')
      if (sticky) {
        setupObserver(sticky)
      }
    }
  }, [])

  const handleScroll = () => {
    if (window.scrollY > 224) {
      divRef.current.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1), 0px 4px 6px -1px rgba(0,0,0,0.06)'
    } else {
      divRef.current.style.boxShadow = '0px -2px 4px -1px rgba(0,0,0,0.1), 0px -1px 3px 0px rgba(0,0,0,0.06)'
    }
  }

  useEffect(() => {
    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', handleScroll)

    setTimeout(() => {
      onResize()
    }, [500])
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('scroll', handleScroll)
      removeObserver()
    }
  }, [onResize])

  return (
    <StoreLocatorProvider>
      <Breadcrumbs productBreadcrumbs={breadcrumbData} />
      <Grid container spacing={2} sx={{ '.MuiGrid-root.MuiGrid-item': { paddingRight: '0 !important' } }}>
        <StoreLocatorBannerContainer item xs={12}>
          <StoreLocatorBanner filters={filters} />
        </StoreLocatorBannerContainer>
        <StyledFilterAndSearch ref={divRef}>
          <StyledStoreSearchBar />
          <StoreFilters filters={filters} />
        </StyledFilterAndSearch>
        <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
          <StoreMap filters={filters} storeLocations={data.stores} />
        </Grid>
        <Grid item xs={12}>
          <SortedStoreList filters={filters} staticData={staticData} />
        </Grid>
      </Grid>
    </StoreLocatorProvider>
  )
}

StoreLocator.propTypes = {
  data: shape({
    stores: arrayOf(object),
  }),
  staticData: oneOfType([object.isRequired, array.isRequired]),
}

export default StoreLocator
