// dependencies
import React, { useState, useEffect, useMemo } from 'react'
import PropTypes, { arrayOf, number, object, shape, string } from 'prop-types'
import _isEqual from 'lodash/isEqual'
// helpers
import { useMyStore } from '@hooks/useMyStore'
import { loadMore } from '@helpers/array-helper'
import { getMarkers, groupStoresByAddress } from '@helpers/store-locator'
import useStoreLocatorContext from '@context/storeLocatorContext/useStoreLocatorContext'
import { Paper, useTheme, styled, useMediaQuery, Button, Stack } from '@mui/material'
// components
import MapLocationsList from './map-locations-list'
import WarningMessage from './warning-message'
import RadarMap from './RadarMap/RadarMap'
import '../../assets/css/components/store-locator/store-locator.sass'

const STORES_MAP_LIST_LIMIT = 26

const StyledPaperContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== 'isMobile',
})(({ isMobile }) => ({
  position: 'relative',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  padding: '0 32px 32px 32px',
  ...(isMobile && { padding: '0 !important' }),
}))

const StoreListWrapper = styled('div')(({ theme }) => ({
  // display: 'flex',
  display: 'grid',
  gridTemplateColumns: '425px 1fr',
  gridTemplateRows: '1fr',
  background: '#ffffff',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '60vw 1fr',
  },
}))

const RadarMapWrapper = styled('div')(({ theme }) => ({
  gridColumn: '2 / 3',
  gridRow: '1 / 2',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
  },
}))

const StoresListContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 0,
  gridColumn: '1 / 2',
  gridRow: '1 / 2',
  [theme.breakpoints.down('md')]: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
  },
}))

const StyledLoadMoreButton = styled(Button)(() => ({
  width: '90%',
  marginTop: '1rem !important',
  alignSelf: 'center',
}))

const StoreMap = ({ filters, storeLocations }) => {
  const stores = useStoreLocatorContext(state => state.stores)
  const setStores = useStoreLocatorContext(state => state.setStores)
  const { myStore } = useMyStore()
  const myStoreInContext = useStoreLocatorContext(state => state.myStore)
  const setMyStoreInContext = useStoreLocatorContext(state => state.setMyStore)
  const currentPosition = useStoreLocatorContext(state => state.currentPosition)
  const [filteredStores, setFilteredStores] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const filteredStoreLocations = storeLocations.filter(store => {
      const storeType = store.StoreType.trim().toLowerCase()
      const storeFilters = filters.state
      return storeFilters[storeType]
    })
    setFilteredStores(filteredStoreLocations)
    // eslint-disable-next-line
  }, [filters])

  // myStore updates frequently in Gatsby's state, which results in excessive rendering and buggy behavior
  // in the Radar Map.  This use effect utilizes "myStoreInContext", which only triggers recalculations
  // when the myStore values actually change.
  useEffect(() => {
    if (!_isEqual(myStoreInContext, myStore) && !!myStore?.storeName) {
      setMyStoreInContext(myStore)
    }
  }, [myStore, myStoreInContext, setMyStoreInContext])

  const markers = useMemo(
    () => (currentPosition.lat ? getMarkers(currentPosition.lat, currentPosition.lng, filteredStores) : []),
    [filteredStores, currentPosition],
  )
  const paginatedMarkers = useMemo(() => {
    if (!isMobile) return markers
    return loadMore(markers, pageNumber)
  }, [isMobile, markers, pageNumber])

  useEffect(() => {
    const tempGroupedStores = groupStoresByAddress(paginatedMarkers)?.slice(0, STORES_MAP_LIST_LIMIT)
    setStores(tempGroupedStores)
  }, [paginatedMarkers, setStores])

  return (
    <>
      <StyledPaperContainer isMobile={isMobile}>
        <StoreListWrapper>
          <StoresListContainer>
            {markers[0]?.distance > 100 ? (
              <WarningMessage isMobile={isMobile} />
            ) : (
              <>
                <MapLocationsList />
                {isMobile && stores.length < markers.length && (
                  <StyledLoadMoreButton
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ mt: 1, mb: 2 }}
                    value="View more"
                    gtm-category="view more stores"
                    gtm-action="view more stores"
                    onClick={() => setPageNumber(prevPageNumber => prevPageNumber + 1)}
                  >
                    VIEW MORE
                  </StyledLoadMoreButton>
                )}
              </>
            )}
          </StoresListContainer>
          <RadarMapWrapper>
            <RadarMap variant="store-locator" includeCurrentMarker />
          </RadarMapWrapper>
        </StoreListWrapper>
      </StyledPaperContainer>
    </>
  )
}

StoreMap.propTypes = {
  filters: PropTypes.object,
  storeLocations: arrayOf(
    shape({
      Address1: string,
      Address2: string,
      City: string,
      Location: shape({
        latLng: shape({
          lat: number,
          lng: number,
        }),
      }),
      PhoneNumber: string,
      State: string,
      StoreHours: object,
      StoreName: string,
      StoreNumber: number,
      StoreType: string,
      Zip: string,
      distance: number,
      id: string,
      slug: string,
    }),
  ),
}

export default StoreMap
