import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { Box, Typography, styled } from '@mui/material'

import Banner from '@templates/strapi-cms/content-types/Banner'
import bgImage from '@assets/images/store-locator-bg.png'

const Wrapper = styled(Box)`
  padding: ${p => p.theme.spacing(2)};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10rem;
  background-image: url(${bgImage});
  flex-direction: column;
  margin-top: ${p => p.theme.spacing(1)};
  background-position: center;
`

const BannerText = styled(Typography)`
  color: #fff;
  font-weight: 400;
`

const StoreLocatorBanner = ({ filters }) => {
  const { state } = filters
  const { allStrapiBanner } = PatioStoresPageBanner()
  const patioStrapiBanner = {
    Banner: {
      ...allStrapiBanner?.edges[0]?.node,
    },
  }

  if (state && state.patio && !state.showroom && !state.kids && !state.outlet) {
    return <Banner data={patioStrapiBanner} />
  }
  return (
    <Wrapper>
      <BannerText variant="h1">ROOMS TO GO STORE LOCATOR</BannerText>
      <BannerText variant="subtitle1">Enter city, state, or zip to shop top quality furniture in your area.</BannerText>
    </Wrapper>
  )
}

StoreLocatorBanner.propTypes = {
  filters: PropTypes.object.isRequired,
}

export default StoreLocatorBanner

const PatioStoresPageBanner = () => {
  const data = useStaticQuery(graphql`
    query PatioStoresBanner {
      allStrapiBanner(filter: { Title: { eq: "Content : Patio Store Locations" } }) {
        edges {
          node {
            ...StrapiPageBannerFragment
          }
        }
      }
    }
  `)
  return data || null
}
